import { graphql } from "gatsby";
import * as React from "react";
import { useMemo, useState } from "react";
import { useArticleOrdered, useSideBarScroll } from "../../../lib/hooks";

// components
import ArticleCard from "../../../components/articlecard";
import CategorySideBar from "../../../components/category-sidebar";
import CategoryHeader from "../../../components/categoryheader";
import HeadTitle from "../../../components/headtitle";
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import Icon from "../../../components/icon";

// types & interfaces
import type { Category, PageProps } from "../../../lib/types";

type MarcheImmoProps = Omit<Category, "subcategories" | "parent">;

type MarcheImmoData = {
  parent: {
    categories: Array<Omit<Category, "parent">>;
  };
};

const MarcheImmo: React.FC<PageProps<MarcheImmoProps, MarcheImmoData>> = ({
  data: {
    page: marche,
    parent: { categories },
  },
  location: { pathname },
}) => {
  const [sideBarOpen, setSideBarSideBarOpen] = useState(false);

  categories = [
    { ...marche, SEO: { slug: "marche-immobilier" } },
    ...categories,
  ];

  // only show categories that have articles
  const categoriesToShow = useMemo(
    () => categories.filter(({ articles }) => articles.length > 0),
    [categories]
  );

  const selectedCategory = useSideBarScroll(categoriesToShow);

  return (
    <>
      <Layout active={"#infos"}>
        <Seo
          title={marche.SEO.title}
          description={marche.SEO.description}
          image={marche.image?.localFile.publicURL}
          pathname={pathname}
        />

        <main className="md:mt-20">
          {/* Cover */}
          <header className={"hidden md:block"}>
            <CategoryHeader title={marche.name} cover={marche.image} />
          </header>

          <section className="grid md:grid-cols-10 lg:grid-cols-12">
            {/* SideBar desktop */}
            <div
              className={`${!sideBarOpen && "hidden"} bg-light-50 pb-40 pt-40 
              md:pt-15 
              md:col-span-3 
              md:static 
              md:px-0 
              md:block
            `}
            >
              <CategorySideBar
                className={"w-full md:sticky md:top-36"}
                open={sideBarOpen}
                activeSlug={selectedCategory}
                onToggle={() => {
                  setSideBarSideBarOpen(false);
                }}
                categoryTitle={marche.name}
                basePath="/pages/marche-immobilier"
                categories={categoriesToShow}
                title={"Marché immobilier"}
              />
            </div>

            <section
              className={`${
                sideBarOpen && "hidden"
              } w-full space-y-4 pt-40 pb-40 
              md:pt-15 
              md:col-span-7 
              md:block
              lg:col-span-9 
            `}
            >
              {/* SideBar mobile */}
              <div
                className={`w-full sticky top-40 px-5 md:hidden`}
                style={{
                  zIndex: 99,
                }}
              >
                <CategorySideBar
                  open={sideBarOpen}
                  activeSlug={categoriesToShow[0].SEO.slug}
                  onToggle={() => {
                    scroll({
                      top: document.querySelector("main").offsetTop - 80,
                      behavior: "smooth",
                    });
                    setSideBarSideBarOpen(true);
                  }}
                  categoryTitle={marche.name}
                  basePath="/pages/marche-immobilier"
                  categories={categoriesToShow}
                  title={"Marché immobilier"}
                />
              </div>

              {/* Categories */}
              <div className="flex flex-col space-y-20 w-full">
                {categoriesToShow.map((category, index) => (
                  <>
                    <CategorySection
                      className={
                        index !== categoriesToShow.length - 1 &&
                        "bg-gradient-to-b"
                      }
                      category={category}
                      key={index}
                      parentSlug={"/marche-immobilier"}
                    />
                    {index !== categoriesToShow.length - 1 && (
                      <div
                        className={`w-full h-10 bg-gradient-to-t from-white to-light-50 !mt-0`}
                      />
                    )}
                  </>
                ))}
              </div>
            </section>
          </section>
        </main>
      </Layout>
    </>
  );
};

export default MarcheImmo;

const CategorySection: React.FC<{
  category: Omit<Category, "parent">;
  className?: string;
  parentSlug: string;
}> = ({ category, parentSlug, className = "" }) => {
  const [displayAll, setDisplayAll] = useState(false);
  const articlesOrdered = useArticleOrdered(category.articles);
  const toShow = displayAll ? articlesOrdered : articlesOrdered.slice(0, 3);

  return (
    <>
      <section
        id={category.SEO.slug}
        className={`${className}
         w-full space-y-4 from-white to-light-50 px-5
         md:px-10 xxl:px-15
      `}
      >
        <HeadTitle as="h2" className="text-xl italic" label={category.name} />
        <p>{category.SEO.description}</p>

        <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3 lg:gap-6 xl:gap-8 xxl:gap-10">
          {toShow.map(article => (
            <React.Fragment key={article.id}>
              <ArticleCard
                article={article}
                categorySlug={`${parentSlug}${
                  category.SEO.slug !== "marche-immobilier"
                    ? `/${category.SEO.slug}`
                    : ""
                }`}
              />
            </React.Fragment>
          ))}
        </div>

        {/* "Show more" button */}
        <div
          className={`
          w-full flex items-center justify-center
          ${category.articles.length <= 3 && "h-20"}
        `}
        >
          {category.articles.length > 3 && (
            <button
              aria-label={"Voir plus d'articles"}
              className={`text-secondary-100 text-center flex flex-col
              justify-center items-center space-y-2 pb-4
              ${
                displayAll
                  ? "hover:animate-bounce-up active:animate-bounce-up"
                  : "hover:animate-bounce-down active:animate-bounce-down"
              }`}
              onClick={_ => setDisplayAll(!displayAll)}
            >
              {displayAll ? (
                <>
                  <div> Voir moins</div>
                  <Icon icon={"arrow-up-dots"} />
                </>
              ) : (
                <>
                  <div> Voir plus</div>
                  <Icon icon={"arrow-down-dots"} />
                </>
              )}
            </button>
          )}
        </div>
      </section>
    </>
  );
};

export const query = graphql`
  query MarcheImmo {
    page: strapiCategorie(
      name: { eq: "Comment se porte le marché de l'immobilier en France ?" }
    ) {
      id: strapiId
      name
      SEO {
        title
        slug
        description
      }

      image {
        localFile {
          publicURL
          childrenImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }
      }
      articles {
        id
        title
        summary
        cover {
          localFile {
            publicURL
            relativePath
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                width: 400
              )
            }
          }
        }
        published_date
        SEO {
          slug
        }
      }
    }

    parent: allStrapiCategorie(
      filter: {
        strapiParent: {
          name: { eq: "Comment se porte le marché de l'immobilier en France ?" }
        }
      }
    ) {
      categories: nodes {
        id
        name
        SEO {
          slug
        }
        articles {
          id
          title
          summary
          cover {
            localFile {
              publicURL
              relativePath
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                  width: 400
                )
              }
            }
          }
          published_date
          SEO {
            slug
          }
        }
      }
    }
  }
`;
